var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c("h5", [_vm._v("Twitter Feeds")]),
      _vm._l(_vm.twitterFeed, function(data, index) {
        return _c(
          "div",
          {
            key: data.avatar,
            staticClass: "profile-twitter-feed",
            class: index != 0 ? "mt-2" : "mt-1"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-start align-items-center mb-1"
              },
              [
                _c("b-avatar", {
                  staticClass: "mr-1",
                  attrs: { size: "40", src: data.imgUrl }
                }),
                _c(
                  "div",
                  { staticClass: "profile-user-info" },
                  [
                    _c("h6", { staticClass: "mb-0" }, [
                      _vm._v(" " + _vm._s(data.title) + " ")
                    ]),
                    _c(
                      "b-link",
                      [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("@" + _vm._s(data.id))
                        ]),
                        _c("feather-icon", {
                          attrs: { icon: "CheckCircleIcon" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "profile-star ml-auto" },
                  [
                    _c("feather-icon", {
                      class: data.favorite ? "profile-favorite" : "",
                      attrs: { icon: "StarIcon", size: "18" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("b-card-text", { staticClass: "mb-50" }, [
              _vm._v(" " + _vm._s(data.desc) + " ")
            ]),
            _c("b-link", [_c("small", [_vm._v(" " + _vm._s(data.tags) + " ")])])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }