var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c("h5", { staticClass: "mb-0" }, [_vm._v(" Latest Photos ")]),
      _c(
        "b-row",
        _vm._l(_vm.latestImages, function(data) {
          return _c(
            "b-col",
            {
              key: data.img,
              staticClass: "profile-latest-img",
              attrs: { md: "4", cols: "6" }
            },
            [
              _c(
                "b-link",
                [
                  _c("b-img", {
                    attrs: {
                      fluid: "",
                      rounded: "",
                      src: data.img,
                      alt: data.img.slice(5)
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }