var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.posts, function(data) {
      return _c(
        "b-card",
        { key: data.avatar },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-start align-items-center mb-1"
            },
            [
              _c("b-avatar", {
                staticClass: "mr-1",
                attrs: { src: data.avatar, size: "50" }
              }),
              _c("div", { staticClass: "profile-user-info" }, [
                _c("h6", { staticClass: "mb-0" }, [
                  _vm._v(" " + _vm._s(data.username) + " ")
                ]),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(data.postTime))
                ])
              ])
            ],
            1
          ),
          _c("b-card-text", [_vm._v(" " + _vm._s(data.postText) + " ")]),
          data.postImg
            ? _c("b-img", {
                staticClass: "mb-25",
                attrs: { fluid: "", rounded: "", src: data.postImg }
              })
            : _vm._e(),
          data.postVid
            ? _c("b-embed", {
                staticClass: "rounded mb-50",
                attrs: {
                  type: "iframe",
                  src: data.postVid,
                  allowfullscreen: ""
                }
              })
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "pb-50 mt-50" },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex justify-content-between justify-content-sm-start mb-2",
                  attrs: { sm: "8" }
                },
                [
                  _c(
                    "b-link",
                    {
                      staticClass:
                        "d-flex align-items-center text-muted text-nowrap"
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        class: data.youLiked ? "profile-likes" : "profile-icon",
                        attrs: { icon: "HeartIcon", size: "18" }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.kFormatter(data.likes)))])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-item-center" },
                    [
                      _c(
                        "b-avatar-group",
                        { staticClass: "ml-1", attrs: { size: "26" } },
                        _vm._l(data.likedUsers, function(avatarData, i) {
                          return _c("b-avatar", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.bottom",
                                value: avatarData.username,
                                expression: "avatarData.username",
                                modifiers: { hover: true, bottom: true }
                              }
                            ],
                            key: i,
                            staticClass: "pull-up",
                            attrs: { src: avatarData.avatar }
                          })
                        }),
                        1
                      ),
                      _c(
                        "b-link",
                        { staticClass: "text-muted text-nowrap mt-50 ml-50" },
                        [_vm._v(" +" + _vm._s(data.likedCount) + " more ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex justify-content-between justify-content-sm-end align-items-center mb-2",
                  attrs: { sm: "4" }
                },
                [
                  _c(
                    "b-link",
                    { staticClass: "text-body text-nowrap" },
                    [
                      _c("feather-icon", {
                        staticClass: "profile-icon mr-50",
                        attrs: { icon: "MessageSquareIcon", size: "18" }
                      }),
                      _c("span", { staticClass: "text-muted mr-1" }, [
                        _vm._v(_vm._s(_vm.kFormatter(data.comments)))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-link",
                    { staticClass: "text-body text-nowrap" },
                    [
                      _c("feather-icon", {
                        staticClass: "profile-icon mr-50",
                        attrs: { icon: "Share2Icon", size: "18" }
                      }),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.kFormatter(data.share)))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(data.detailedComments, function(comment, ind) {
            return _c(
              "div",
              { key: ind, staticClass: "d-flex align-items-start mb-1" },
              [
                _c("b-avatar", {
                  staticClass: "mt-25 mr-75",
                  attrs: { src: comment.avatar, size: "34" }
                }),
                _c("div", { staticClass: "profile-user-info w-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _c("h6", { staticClass: "mb-0" }, [
                        _vm._v(" " + _vm._s(comment.username) + " ")
                      ]),
                      _c(
                        "b-link",
                        { staticClass: "text-body" },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-25",
                            class: comment.youLiked
                              ? "profile-likes"
                              : "profile-icon",
                            attrs: { icon: "HeartIcon", size: "18" }
                          }),
                          _c(
                            "span",
                            { staticClass: "text-muted align-middle" },
                            [_vm._v(_vm._s(comment.commentsLikes))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("small", [_vm._v(_vm._s(comment.comment))])
                ])
              ],
              1
            )
          }),
          _c(
            "b-form-group",
            [
              _c("b-form-textarea", {
                attrs: { rows: "3", placeholder: "Add Comment" }
              })
            ],
            1
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { size: "sm", variant: "primary" }
            },
            [_vm._v(" Post Comment ")]
          )
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }