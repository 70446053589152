var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center mb-2" },
    [
      _c(
        "b-overlay",
        {
          staticClass: "d-inline-block",
          attrs: {
            show: _vm.show,
            rounded: "sm",
            "spinner-variant": "primary",
            "spinner-small": ""
          }
        },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(186, 191, 199, 0.15)",
                  expression: "'rgba(186, 191, 199, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "primary", size: "sm" },
              on: { click: _vm.onClick }
            },
            [_vm._v(" Load More ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }