var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c("h5", { staticClass: "mb-1" }, [_vm._v(" Polls ")]),
      _c("b-card-text", [
        _vm._v("Who is the best actor in Marvel Cinematic Universe?")
      ]),
      _vm._l(_vm.pollsData, function(data) {
        return _c(
          "div",
          { key: data.name, staticClass: "polls-info mt-2" },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c(
                  "b-form-radio",
                  {
                    staticClass: "mb-25",
                    attrs: { name: "polls", value: data.username },
                    model: {
                      value: _vm.Selected,
                      callback: function($$v) {
                        _vm.Selected = $$v
                      },
                      expression: "Selected"
                    }
                  },
                  [_vm._v(" " + _vm._s(data.name) + " ")]
                ),
                _c("div", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(data.result) + " ")
                ])
              ],
              1
            ),
            _c("b-progress", {
              staticClass: "my-50",
              attrs: { value: data.result, max: "100", height: "6px" }
            }),
            _c(
              "b-avatar-group",
              { staticClass: "mt-1", attrs: { size: "26" } },
              _vm._l(data.votedUser, function(avatar) {
                return _c("b-avatar", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.bottom",
                      value: avatar.username,
                      expression: "avatar.username",
                      modifiers: { hover: true, bottom: true }
                    }
                  ],
                  key: avatar.img,
                  staticClass: "pull-up",
                  attrs: { src: avatar.img }
                })
              }),
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }